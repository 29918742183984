@font-face {
  font-family: 'ModelicaBold';
  src: url('./assets/fonts/BwModelica-Bold.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'ModelicaMedium';
  src: url('./assets/fonts/BwModelica-Medium.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'ModelicaLigth';
  src: url('./assets/fonts/BwModelica-Light.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

h1, h2, h3{
  font-family: "ModelicaBold" !important;
}
p, span{
  font-family: "ModelicaMedium" !important;
}
button, input, label, div{
  font-family: "ModelicaLigth" !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
